.l-content {
  max-width: 1200px;
  width: 100%;
  margin: auto;

  &--bg {
    padding: 80px 0;
    background: $white;
    border: 1px solid $gray-no2;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    @include mq(sp) {
      width: calc(100% - 30px);
      padding: 40px 0;
    }
  }
}