.p-pager {
  $class: &;

  width: 100%;
  max-width: 970px;
  margin: 40px auto -80px;
  padding: 40px 0;
  border-top: 1px solid $gray-no2;
  position: relative;
  clear: both;

  @include mq(sp) {
    margin: 40px auto -60px;
    padding: 20px 0 40px;
  }

  &__list {
    display: flex;
    justify-content: space-between;

    @include mq(sp) {
      width: calc(100% - 30px);
      margin: auto;
    }

    li {
      @include transition(all, 0.3s);
    }

    a {
      color: $green;
    }
  }

  &__prev {
    &::before {
      display: inline-block;
      content: "";
      width: 8px;
      height: 14px;
      margin-right: 15px;
      background: url('../images/ico_prev02.svg') no-repeat;
    }

    &:hover {
      transform: translateX(-5px);
    }
  }

  &__next {
    &::after {
      display: inline-block;
      content: "";
      width: 8px;
      height: 14px;
      margin-left: 15px;
      background: url('../images/ico_next02.svg') no-repeat;
    }

    &:hover {
      transform: translateX(5px);
    }
  }

  &__btn {
    margin-top: -30px;

    @include mq(sp) {
      margin-top: 30px;
    }
  }
}