.p-mail-heading {
  &__ttl {
    font-family: $font-min;
    font-size: 24px;
    line-height: 1.5;
    color: $green;

    @include mq(sp) {
      font-size: 20px;
    }
  }

  &__lead {
    margin: 20px 0;
    line-height: 1.5;
    font-family: $font-min;
    color: $gray;

    @include mq(sp) {
      font-size: 14px;
    }
  }
}