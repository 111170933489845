/* --------------------------

  会員一覧内のスタイル

-------------------------- */

.p-mail-card {
  padding: 40px;
  margin-bottom: 30px;
  font-family: $font-min;
  background: $white;
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);

  @include mq(sp) {
    padding: 20px;
    margin-bottom: 20px;
  }

  &:first-child {
    @include mq(sp) {
      margin-top: 20px;
    }
  }

  pre {
    line-height: 1.5;
    overflow: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  &__ttl {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 1.5;
    color: $green;

    @include mq(sp) {
      margin-bottom: 10px;
      font-size: 20px;
    }

    + p {
      margin-top: -10px;
      line-height: 1.5;
      font-family: $font-min;
      color: $gray;

      @include mq(sp) {
        margin-top: 0px;
        font-size: 14px;
      }
    }
  }

  &__body {
    margin: 20px 0;
    padding: 20px 0;
    border-top: 1px solid $gray-no2;
  }
  
  &__body-ttl {
    margin-bottom: 10px;
    line-height: 1.5;
    color: $green;
  }

  &__body-heading {
    line-height: 1.5;
    
    li {
      line-height: 1.5;
      margin-top: 15px;

      @include mq(sp) {
        margin-top: 10px;
      }
    }

    h1 {
      margin-top: 10px;
      font-family: $font-min;
    }

    pre {
      &:not(:first-child) {
        display: none;
      }
    }

    h2, h3, h4, ul, ol, img, blockquote, pre {
      display: none;
    }
  }
}