.p-media-item {
  &:not(:first-child) {
    margin-top: 80px;

    @include mq(sp) {
      margin-top: 40px;
    }
  }

  &__img {
    max-width: 584px;

    @include mq(sp) {
      max-width: 100%;
      margin: 0 20px;
    }
  }

  &__body {
    max-width: 430px;
    margin: 20px 60px 0;

    @include mq(sp) {
      max-width: 100%;
      margin: 20px 20px 0;
    }
  }

  &__ttl {
    font-family: $font-min;
    font-size: 24px;
    line-height: 1.5;
    margin-bottom: 20px;

    @include mq(sp) {
      font-size: 20px;
    }
  }

  &__text {
    font-size: 14px;
    line-height: 30px;
  }
}