.p-submv {
  $class: &;

  width: 100%;
  height: 300px;
  margin-top: 94px;
  background: url('../images/img_submv01.jpg') no-repeat center center;
  background-size: cover;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 200;

  @include mq(tb) {
    margin-top: 80px;
  }
  
  @include mq(sp) {
    height: 200px;
  }

  &::before {
    display: block;
    content: "";
    width: 223px;
    height: 300px;
    background: url('../images/img_submv-deco01.png') no-repeat center;
    background-size: 100%;
    @include h-Centering;

    @include mq(sp) {
      width: 149px;
      height: 200px;
    }
  }

  &__ttl {
    font-family: $font-min;
    text-align: center;
    font-size: 45px;
    color: $white;
    @include centering;

    @include mq(sp) {
      font-size: 24px;
      white-space: nowrap;
    }

    span {
      display: block;
      margin-top: 15px;
      font-size: 20px;

      @include mq(sp) {
        font-size: 12px;
      }
    }
  }

  &--secondary {
    background: url('../images/img_submv02.jpg') no-repeat;
    background-size: cover;

    &::before {
      background: url('../images/img_submv-deco02.png') no-repeat center;
      background-size: 100%;
    }

    #{$class}__ttl {
      color: $black;
    }
  }

  &--third {
    background: url('../images/img_submv03.jpg') no-repeat 50% 50%;
    background-size: cover;

    &::before {
      background: url('../images/img_submv-deco02.png') no-repeat center;
      background-size: 100%;
    }

    #{$class}__ttl {
      color: $black;
    }
  }

  &--fourth {
    background: url('../images/img_submv04.jpg') no-repeat;
    background-size: cover;

    &::before {
      background: url('../images/img_submv-deco02.png') no-repeat center;
      background-size: 100%;
    }

    #{$class}__ttl {
      color: $black;
    }
  }

  &--fifth {
    background: url('../images/img_submv05.jpg') no-repeat 50% 50%;
    background-size: cover;

    &::before {
      background: url('../images/img_submv-deco02.png') no-repeat center;
      background-size: 100%;
    }

    #{$class}__ttl {
      color: $black;
    }
  }

  &--sixth {
    background: url('../images/img_submv06.jpg') no-repeat 50% 50%;
    background-size: cover;

    &::before {
      background: url('../images/img_submv-deco02.png') no-repeat center;
      background-size: 100%;
    }

    #{$class}__ttl {
      color: $black;
    }
  }
}