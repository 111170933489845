.c-lead {
  font-family: $font-min;
  font-size: 30px;
  line-height: 50px;
  color: $green;

  @include mq(sp) {
    font-size: 20px;
    line-height: 32px;
  }

  &--small {
    margin: 20px 0;
    line-height: 1.5;
    font-family: $font-min;
    color: $gray;

    @include mq(sp) {
      font-size: 14px;
    }
  }
}