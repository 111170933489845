.c-table {
  $class: &;
  width: 100%;

  &__item {
    border-bottom: 1px solid $gray-no2;

    &:first-child {
      border-top: 1px solid $gray-no2;
    }

    &:nth-child(odd) {
      background: $gray-no3;
    }
  }

  #{$class}__ttl,
  #{$class}__content {
    font-size: 14px;

    @include mq(sp) {
      display: block;
      width: 100%;
    }
  }

  &__ttl {
    min-width: 215px;
    padding: 30px 20px;
    font-weight: bold;
    text-align: left;
    vertical-align: top;
    transform: translateY(8px);

    @include mq(sp) {
      padding: 20px 10px 15px;
    }
  }

  &__content {
    width: 100%;
    padding: 30px 20px;
    line-height: 2;
    
    @include mq(sp) {
      padding: 0 10px 20px;
    }
  }

  &__map {
    position: relative;
    margin: 20px 0;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}