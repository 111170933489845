.l-footer-bnr {
  width: 100%;
  max-width: 1170px;
  margin: auto;
  padding: 40px 0 60px;
  display: flex;
  justify-content: space-between;

  @include mq(sp) {
    width: calc(100% - 30px);
    flex-direction: column;
    padding: 20px 0 30px;
    margin: 0 15px;
  }

  li {
    width: 100%;
    border: 1px solid $gray-no2;

    &:not(:first-child) {
      margin-left: 30px;

      @include mq(sp) {
        margin-left: 0;
        margin-top: 20px;
      }
    }

    a {
      display: block;
      @include media-mouseover;
    }
  }
}