.p-message {
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  margin: 60px 0;

  @include mq(tb) {
    flex-direction: column;
    margin: 30px 0;
  }

  &__img {
    width: calc(35% + 80px);
    margin: -24% -80px 0 40px;
    position: relative;
    z-index: 0;

    @include mq(tb) {
      width: 80%;
      max-width: 360px;
      margin: 0 auto;
    }

    &--pc {
      display: block;

      @include mq(tb) {
        display: none;
      }
    }

    &--tb {
      display: none;

      @include mq(tb) {
        display: block;
      }
    }
  }

  &__text {
    width: 65%;
    line-height: 2.25;

    @include mq(tb) {
      width: 100%;
      margin: 20px 0 0 0;
    }
  }
}