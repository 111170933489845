// Media Query
@mixin mq($breakPoint) {
  @media #{map-get($breakPoints, $breakPoint)} {
    @content;
  }
}

// Centering
@mixin centering {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// VerticalCenter
@mixin v-Centering {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

// HorizontalCenter
@mixin h-Centering {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

// Transition
@mixin transition($property,$time) {
  transition: $property $time cubic-bezier(0.215, 0.61, 0.355, 1);
}

// Media Mouseover
@mixin media-mouseover {
  @include transition(opacity, 0.3s);
  
  &:hover {
    opacity: .7;
  }
}