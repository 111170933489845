.c-figure {
  &--center {
    max-width: 624px;
    margin: auto;
  }

  &__caption {
    margin: 10px 0 0 0;
    font-size: 12px;
    line-height: 2;
    text-align: center;
    background: $white;
  }
}