.p-num {
  margin: 20px 0;
  font-size: 40px;
  color: $green;
  font-weight: bold;

  span {
    display: block;
    margin-top: 10px;
    font-size: 14px;
    font-weight: normal;
    color: $black;
  }
}