@for $value from 1 to 10 {
  .u-order-#{$value} {
    order: $value;
  }

  .u-order-#{$value}--sp {
    @include mq(sp) {
      order: $value;
    }
  }
}