.p-pager-list {
  display: flex;
  justify-content: center;

  &__btn {
    display: block;
    width: 40px;
    height: 40px;
    margin-left: -1px;
    text-align: center;
    line-height: 40px;
    background: $white;
    border: 1px solid $gray-no2;
    @include transition(all, 0.3s);

    @include mq(sp) {
      display: none;
    }

    &:hover {
      color: $green;
      background: $gray-no3;
    }

    &--active {
      color: $white;
      background: $green;
      border: 1px solid $green;

      @include mq(sp) {
        display: block;
      }
    }

    &--active:hover {
      color: $white;
      background: $green;
    }

    &--disabled {
      cursor: auto;
    }

    &--disabled:hover {
      color: $black;
      background: $white;
    }
  }

  &__prev, &__next {
    width: 62px;

    @include mq(sp) {
      display: block;
    }
  }

  &__prev {
    border-radius: 8px 0 0 8px;
  }

  &__next {
    border-radius: 0 8px 8px 0;
  }
}