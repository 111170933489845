.wp-pagenavi {
  display: flex;
  justify-content: center;
  width: calc(75% - 40px);
  margin: 20px 0;

  @include mq(sp) {
    position: relative;
    width: 100%;
  }
  
  span,
  a {
    margin: 0 !important;
  }

  a {
    background: $white;
    border-color: $gray-no2 !important;

    &:hover {
      color: $green;
      background: $gray-no3;
    }
  }

  .page,
  .current {
    display: block;
    width: 40px;
    height: 40px;
    padding: 0 !important;
    margin-left: -1px !important;
    text-align: center;
    line-height: 40px;
    background: $white;
    border: 1px solid $gray-no2;
    @include transition(all, 0.3s);
  }

  .page {
    @include mq(sp) {
      display: none;
    }
  }

  .current {
    font-weight: normal !important;
    color: $white;
    background: $green;
    border-color: $gray-no2 !important;
  }

  .previouspostslink,
  .nextpostslink {
    width: 62px;
    text-align: center;
    line-height: 32px;

    @include mq(sp) {
      width: 35%;
      max-width: 160px;
      position: absolute;
    }
  }

  .previouspostslink {
    border-radius: 8px 0 0 8px;

    @include mq(sp) {
      left: 20px;
    }
  }

  .nextpostslink {
    margin-left: -1px !important;
    border-radius: 0 8px 8px 0;

    @include mq(sp) {
      right: 20px;
    }
  }
}