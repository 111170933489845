.l-block {
  max-width: 780px;
  margin: auto;
  
  @include mq(tb) {
    padding: 0 60px;
  }

  @include mq(sp) {
    padding: 0 20px;
  }
}