.l-gnav {
  $class: &;
  align-self: stretch;

  @include mq(tb) {
    width: 100%;
    align-self: auto;
    position: absolute;
    top: 0;
    left: 0;
  }

  &__menu {
    cursor: pointer;
    position: absolute;
    top: 25px;
    left: 12px;
    z-index: 1000;
    display: none;

    @include mq(tb) {
      display: block;
      
      span {
        @include transition(all, 0.3s);
      }

      &.on {
        #{$class}__menu-top {
          transform: translateX(0) translateY(9px) rotate(45deg)
        }

        #{$class}__menu-middle {
          transform: translateX(80%);
          opacity: 0;
        }

        #{$class}__menu-bottom {
          transform: translateX(0px) translateY(-9px) rotate(-45deg);
        }
      }
    }
  }

  &__menu-ico {
    width: 24px;
    height: 20px;
    margin: 0 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    & > * {
      display: block;
      width: 100%;
      height: 2px;
      background: $green;
    }
  }

  &__menu-text {
    margin-top: 5px;
    transform: scale(0.6);
    transform-origin: left center;
    font-family: $font-min;
    color: $green;
  }

  &__list {
    display: flex;
    align-self: stretch;
    height: 100%;
    font-family: $font-min;
    text-align: center;
    background: $white;

    @include mq(tb) {
      flex-direction: column;
      width: 100%;
      height: 100vh;
      padding: 80px 0;
      @include transition(all, 0.3s);
      overflow: hidden;
      position: absolute;
      left: 100vw;

      &.on {
        left: 0;
        overflow-y: scroll;
        @include transition(all, 0.3s);

        li {
          opacity: 1;
          transform: translateY(0);
        }
      }
    }

    li {
      font-size: 14px;
      position: relative;

      @include mq(tb) {
        text-align: left;
        opacity: 0;
        transform: translateY(5vh);
        transition: transform 0.5s 0.3s, opacity 0.3s 0.3s ease-out;
        
        &:not(:last-child) {
          border-top: 1px solid $gray-no2;
        }
      }

      &:nth-child(6) {
        display: none;

        @include mq(tb) {
          display: block;
        }
      }
    }

    a {
      display: block;
      height: 100%;
      padding: 29px 1.08vw;
      @include transition(all, 0.3s);
      position: relative;

      @include mq(tb) {
        height: auto;
        padding: 20px 15px;
      }

      &::after {
        display: block;
        content: "";
        width: 100%;
        height: 0;
        background: $green;
        @include transition(all, 0.3s);
        position: absolute;
        left: 0;
        bottom: 0;
      }

      &:hover {
        color: $green;

        span {
          color: $green;
        }

        &::after {
          height: 4px;

          @include mq(tb) {
            height: 0px;
          }
        }
      }
    }

    span {
      display: block;
      margin-top: 10px;
      font-size: 10px;
      color: $gray;
    }

    .current {
      color: $green;

      span {
        color: $green;
      }

      &::after {
        height: 4px;

        @include mq(tb) {
          height: 0px;
        }
      }
    }
  }

  &__login {
    margin-left: 1.08vw;

    @include mq(tb) {
      margin: 10px 15px;
    }

    a {
      display: block;
      color: $white;
      background: $green;
      @include media-mouseover;

      @include mq(tb) {
        border-radius: 8px;
        text-align: center;
      }

      &:hover {
        color: $white;

        span {
          color: $white;
        }
      }
    }

    span {
      color: $white;
    }
  }

  &__inner-list {
    display: block;
    width: 200px;
    background: $white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 94px;
    left: 0;

    @include mq(tb) {
      width: 100%;
      height: 0;
      visibility: visible;
      overflow: hidden;
      box-shadow: none;
      background: $gray-no3;
      transform: translateY(0);
      @include transition(all, 0.5s);
      position: static;

      &.show {
        height: auto;
        opacity: 1;
      }
    }

    li {
      overflow: hidden;
      height: 0;
      @include transition(all, 0.3s);

      @include mq(tb) {
        overflow: visible;
        height: 54px;

        &:not(:first-child) {
          border-top: 1px solid $gray-no2;
        }
      }
      

      &.active {
        overflow: visible;
        height: 54px;

        &:not(:first-child) {
          border-top: 1px solid $gray-no2;
        }
      }
    }

    a {
      padding: 20px 1.5vw;

      @include mq(tb) {
        padding: 20px 15px;
      }
    }

    a::after {
      background: $gray-no3;
    }

    a:hover {
      background: $gray-no3;
    }
  }

  &__inner-trigger {
    display: none;
    width: 60px;
    height: 60px;
    background: url('../images/ico_next.svg') no-repeat center center;
    background-size: 20%;
    transform: rotate(90deg);
    @include transition(all, 0.3s);
    position: absolute;
    top: 7px;
    right: 0;
    z-index: 100;

    &.show {
      transform: rotate(-90deg);
    }

    @include mq(tb) {
      display: block;
    }
  }
}