.p-history-list {
  li {
    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  &__month {
    width: 8%;

    @include mq(sp) {
      width: 100%;
    }
  }

  &__text {
    width: calc(92% - 10px);
    margin-left: 10px;

    @include mq(sp) {
      width: 100%;
      margin-left: 0;
    }
  }
}