.p-text-box {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: stretch;
  margin: 20px 0 30px;
  padding: 30px;
  border: 1px solid $gold;
  position: relative;

  @include mq(sp) {
    margin: 20px 0;
    padding: 20px;

    &:not(:first-child) {
      margin-top: 20px;
    }
  }

  &__ttl {
    display: inline-block;
    padding: 10px 20px;
    font-family: $font-min;
    font-size: 24px;
    text-align: center;
    white-space: nowrap;
    background: $white;
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);

    @include mq(tb) {
      font-size: 20px;
    }
  }

  &__text {
    width: 100%;
    font-size: 14px;
    line-height: 30px;
  }

  &__list {
    width: 100%;
    margin-top: 20px;
    font-size: 14px;
    line-height: 30px;
  }
}