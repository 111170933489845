/* font-size - utility
------------------------*/
@for $value from 1 to 10 {
  .u-fz-#{$value * 2 + 8} {
    font-size: 2px * $value + 8;
  }
}

/* font-weight
------------------------*/
.u-fw-normal {
  font-weight: normal;
}

.u-fw-bold {
  font-weight: bold;
}

/* text-align
------------------------*/
.u-ta-left {
  text-align: left;
}

.u-ta-center {
  text-align: center;
}

.u-ta-right {
  text-align: right;
}

/* text-decoration
------------------------*/
.u-td-line {
  text-decoration: underline;
}