.l-wrapper {
  width: 100%;
  height: 100%;
  padding: 80px 0;
  background: $white url('../images/bg_content.png') no-repeat left top;
  background-size: 100% 100%;
  position: relative;
  z-index: 2;

  @include mq(sp) {
    padding: 30px 0;
  }
}