.l-mail-list {
  width: calc(75% - 40px);
  margin-right: 40px;

  @include mq(sp) {
    width: 100%;
    margin: 0;
  }

  &--bg {
    padding: 80px 5vw;
    background: $white;
    border: 1px solid $gray-no2;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    @include mq(sp) {
      width: calc(100% - 30px);
      margin: auto;
      padding: 40px 20px;
    }
  }
}