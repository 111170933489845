* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: $black;
  background: $white;
  font-family: $font-family;
  font-size: $font-size;
  font-feature-settings: "palt" 1;
  counter-reset: number 0;
  position: relative;
}

main {
  overflow: hidden;
  font-family: $font-family;
}

h1 {
  margin: 0;
}

li {
  list-style: none;
}

p {
  line-height: 1.6;
  letter-spacing: 1px;
}

a {
  color: $black;
  text-decoration: none;
  outline: none;
}

i {
  font-style: normal;
}

img {
  display: block;
  width: 100%;
}

input, button, textarea, select {
  appearance: none;
  border: none;
}

input:focus,
button:focus,
select:focus {
  outline: none;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  appearance: none;
}

select::-ms-expand {
  display: none;
}