.l-subnav {
  width: 100%;
  background: #fcfcfc;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 100;

  &__list {
    display: flex;
    justify-content: center;
    font-family: $font-min;
    text-align: center;

    @include mq(sp) {
      overflow-x: scroll;
    }

    &--left {
      justify-content: flex-start;
    }

    li {
      width: 150px;
      font-size: 14px;
      white-space: nowrap;

      @include mq(sp) {
        font-size: 12px;
      }
    }

    a {
      display: block;
      height: 100%;
      padding: 20px;
      @include transition(all, 0.3s);
      position: relative;

      &::after {
        display: block;
        content: "";
        width: 100%;
        height: 0;
        background: $green;
        @include transition(all, 0.3s);
        position: absolute;
        left: 0;
        bottom: 0;
      }

      &:hover {
        color: $green;

        span {
          color: $green;
        }

        &::after {
          height: 4px;
        }
      }
    }

    span {
      display: block;
      margin-top: 10px;
      font-size: 10px;
      color: $gray;

      @include mq(sp) {
        display: none;
      }
    }

    .current {
      color: $green;

      span {
        color: $green;
      }

      &::after {
        height: 4px;
      }
    }
  }
}