/* --------------------
Marign
-------------------- */
// m  = marign
// mt = top
// mr = right
// mb = bottom
// ml = left
// mh = horizontal
// mv = vertical

$mMaXNum: 10;

@for $i from 0 through 10 {
  .u-mt#{$i * $mMaXNum} {
    margin-top: #{$i * $mMaXNum}px;

    @include mq(sp) {
      margin-top: #{($i * $mMaXNum) / 2}px;
    }
  }
  .u-mr#{$i * $mMaXNum} {
    margin-right: #{$i * $mMaXNum}px;
  }
  .u-mb#{$i * $mMaXNum} {
    margin-bottom: #{$i * $mMaXNum}px;

    @include mq(sp) {
      margin-bottom: #{($i * $mMaXNum) / 2}px;
    }
  }
  .u-ml#{$i * $mMaXNum} {
    margin-left: #{$i * $mMaXNum}px;
  }
  .u-mh#{$i * $mMaXNum} {
    margin: 0 #{$i * $mMaXNum}px;
  }
  .u-mv#{$i * $mMaXNum} {
    margin: #{$i * $mMaXNum}px 0;

    @include mq(sp) {
      margin: #{($i * $mMaXNum) / 2}px 0;
    }
  }
}