.l-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  background: $white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 0;
  z-index: 9000;

  @include mq(tb) {
    display: block;
    padding: 0;
    background: transparent;
    box-shadow: none;
    position: fixed;
    top: 0;
    @include transition(all, 0.3s);

    &.show {
      background: $white;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }
  }

  &.fixed {
    position: fixed;
    top: 0;
  }

  &--top {
    top: calc(100vh - 94px);

    @include mq(tb) {
      top: 0;
    }
  }

  &--page {
    background: $white;
    position: fixed;
    top: 0;

    @include mq(tb) {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }
  }

  &__logo {
    padding: 25px 0;
    font-family: $font-min;
    font-size: 24px;

    @include mq(tb) {
      padding: 20px 0;
      font-size: 20px;
      text-align: center;
    }

    a {
      display: block;
      color: $green;
    }

    span {
      display: block;
      margin-top: 10px;
      font-size: 10px;
      color: $gray;

      @include mq(tb) {
        transform: scale(0.8);
      }
    }
  }
}