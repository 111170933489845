/* --------------------
padding
-------------------- */
// p  = marign
// pt = top
// pr = right
// pb = bottom
// pl = left

$pMaXNum: 50;

@for $i from 0 through 50 {
  .u-pt#{$i * $pMaXNum} {
    padding-top: #{$i * $pMaXNum}px;
  }
  .u-pr#{$i * $pMaXNum} {
    padding-right: #{$i * $pMaXNum}px;
  }
  .u-pb#{$i * $pMaXNum} {
    padding-bottom: #{$i * $pMaXNum}px;
  }
  .u-pl#{$i * $pMaXNum} {
    padding-left: #{$i * $pMaXNum}px;
  }
}