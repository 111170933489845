.p-idea {
  width: 100%;
  
  &__item {
    width: 33%;

    @include mq(sp) {
      width: calc(100% - 40px);
      max-width: 360px;
      margin: auto;
    }
  }
}