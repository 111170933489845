.p-card {
  $class: &;
  position: relative;

  a {
    display: block;
    width: 100%;
    height: 100%;
    background: $white;
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
    @include transition(all, 0.3s);
    
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);

      #{$class}__thumb {
        opacity: 0.7;
      }

      #{$class}__ttl {
        color: $green;
      }
    }
  }

  &__body {
    padding: 20px;
  }

  &__thumb {
    width: 100%;
    padding: 62.17% 0 0;
    background: $gray-no3;
    overflow: hidden;
    position: relative;

    img {
      @include centering;
      width: auto;
      max-width: 100%;
    }
  }

  &__ttl {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1.5;
    word-wrap: break-word;
  }

  &__date {
    font-family: $font-min;
    font-size: 10px;
    color: $gray-no4;
    position: absolute;
    bottom: 20px;
  }
}