.p-work {
  &__item {
    padding: 10px 0;

    &:not(:first-child) {
      margin-top: 40px;

      @include mq(sp) {
        margin-top: 20px;
      }
    }
  }

  &__ttl {
    line-height: $line-height;

    @include mq(sp) {
      margin-bottom: 20px;
    }

    &--center {
      @include mq(sp) {
        text-align: center;
      }
    }

    span {
      display: inline-block;
      width: 50px;
      height: 50px;
      margin-right: 20px;
      text-align: center;
      line-height: 25px;
      color: $white;
      position: relative;
      z-index: 100;
  
      @include mq(sp) {
        display: block;
        margin: auto;
        width: 40px;
        height: 40px;
        line-height: 20px;
      }
  
      &::after {
        display: inline-block;
        content: "";
        width: 100%;
        height: 100%;
        background: url('../images/ico_no.svg');
        background-size: cover;
        position: absolute;
        top: -13px;
        left: 0;
        z-index: -1;
  
        @include mq(sp) {
          top: -10px;
        }
      }
    }
  }

  &__text {
    font-size: 14px;
    line-height: 2;
  }
}