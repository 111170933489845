.p-post {
  display: flex;
  font-size: 12px;
  font-family: $font-min;
  color: $gray;

  &__data {
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px solid $gray;
  }
}