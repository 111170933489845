$black: #000;
$white: #fff;
$green: #0d7600;
$green-no2: #bde0b9;
$gold: #bbad00;
$gray: #777777;
$gray-no2: #dbdbdb;
$gray-no3: #f7f7f7;
$gray-no4: #9b9b9b;

$color-text: $black;
$color-bg: $white;

$color-link: $green;
$color-link-visited: #00129f;