.p-post-year {
  width: 25%;
  position: relative;

  @include mq(sp) {
    width: 100%;
    margin: 20px 0 0;
  }

  &__wrap {
    width: 20vw;
    max-width: 250px;
    position: static;

    @include mq(sp) {
      width: 100%;
      max-width: 100%;
    }

    &--fixed {
      position: fixed;
      top: 140px;
    }

    &--stop {
      position: absolute;
      top: auto;
      bottom: 0;
    }
  }

  &__wrap--space {
    @include mq(sp) {
      padding: 0 15px;
    }
  }

  &__ttl {
    padding: 15px 20px;
    font-family: $font-min;
    color: $white;
    background: #363636;
  }

  &__list {
    li {
      &:not(:first-child) {
        border-top: 1px solid $gray-no2;
      }
    }

    a {
      display: block;
      padding: 20px;
      background: $gray-no3;
      @include transition(all, 0.3s);
      position: relative;

      &::before {
        display: block;
        content: "";
        width: 4px;
        height: 1em;
        background: $green;
        position: absolute;
        top: 50%;
        left: 2px;
        transform: translateY(-50%) translateX(-4px);
        @include transition(all, 0.3s);
        opacity: 0;
      }

      &:hover {
        color: $green;

        &::before {
          transform: translateY(-50%) translateX(-2px);
          opacity: 1;
        }
      }

      @include mq(sp) {
        padding: 15px 20px;
      }
    }

    .active {
      a {
        color: $green;

        &::before {
          transform: translateY(-50%) translateX(-2px);
          opacity: 1;
        }
      }
    }
  }
}