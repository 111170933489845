.c-grid {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  @include mq(sp) {
    flex-direction: column;
  }

  &--center {
    justify-content: center;
  }

  &--between {
    justify-content: space-between;
  }

  &--end {
    justify-content: flex-end;
  }

  &--reverse {
    flex-direction: row-reverse;

    @include mq(sp) {
      flex-direction: column;
    }
  }

  &--v-start {
    align-items: flex-start;
  }

  &__col1 {
    &:not(:first-child) {
      margin-top: 30px;
    }
  }

  &__col2 {
    width: calc(50% - 10px);

    @include mq(sp) {
      width: 100%;
    }

    &:not(:nth-child(2n)) {
      margin-right: 20px;

      @include mq(sp) {
        margin-right: 0;
      }
    }
  }

  &__col3 {
    width: calc(34.16% - 30px);
    margin-bottom: 30px;

    @include mq(sp) {
      width: calc(100% - 30px);
      margin: 0 15px 20px;
    }
    
    &:not(:nth-child(3n)) {
      margin-right: 30px;

      @include mq(sp) {
        margin-right: 0;
      }
    }
  }

  &__col4 {
    width: calc(25% - 8px);

    @include mq(sp) {
      width: 100%;
    }

    &:not(:nth-child(4n)) {
      margin-right: 10px;

      @include mq(sp) {
        margin-right: 0;
      }
    }
  }
}