.p-intro {
  &__img {
    max-width: 624px;
    margin: auto;
    position: relative;
    z-index: 100;

    @include mq(sp) {
      width: calc(100% - 40px);
    }
  }

  &__content {
    width: 100%;
    padding: 80px 0;
    margin-top: -40px;
    position: relative;
    z-index: 50px;

    @include mq(tb) {
      padding: 80px 0 40px;
    }

    @include mq(sp) {
      padding: 60px 0 20px;
    }

    &::after {
      display: block;
      content: "";
      width: calc(100% - 9%);
      height: 100%;
      background: $gray-no3;
      position: absolute;
      top: 0;

      @include mq(tb) {
        width: 100%;
      }
    }
  }

  &__text {
    position: relative;
    z-index: 50;

    @include mq(tb) {
      padding: 0 20px;
    }
  }
}