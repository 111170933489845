.c-link {
  color: $green;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.c-link-external {
  &::after {
    display: inline-block;
    content: "";
    width: 13px;
    height: 11px;
    margin-left: 5px;
    background: url(../images/ico_link.svg);
  }
}

.c-link-pdf {
  &::after {
    display: inline-block;
    content: "";
    width: 12px;
    height: 14px;
    margin-left: 5px;
    background: url(../images/ico_pdf.svg);
  }
}