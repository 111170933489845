.p-chart {
  position: relative;

  &__ttl {
    margin-bottom: 20px;
    font-family: $font-min;
    font-size: 24px;
    text-align: center;

    @include mq(sp) {
      font-size: 20px;
    }

    span {
      margin-top: 10px;
      display: block;
      font-size: 14px;

      @include mq(sp) {
        font-size: 12px;
      }
    }
  }

  
  &__img--pc {
    display: block;

    @include mq(sp) {
      display: none;
    }
  }

  &__img--sp {
    display: none;

    @include mq(sp) {
      display: block;
    }
  }

  &__caption {
    font-size: 14px;
    line-height: 24px;
    position: absolute;
    right: 115px;
    bottom: -30px;

    @include mq(tb) {
      position: static;
      margin-top: 10px;
    }

    @include mq(sp) {
      font-size: 10px;
      line-height: 17px;
    }
  }
}