.p-article {
  width: 100%;

  @include mq(sp) {
    width: 100%;
    margin: 0;
  }

  &__ttl {
    margin: 30px 0;
    font-size: 30px;
    line-height: 1.5;
    font-family: $font-min;
    word-wrap: break-word;

    @include mq(sp) {
      margin: 20px 0;
      font-size: 24px;
    }

    &::after {
      display: block;
      content: "";
      width: 100%;
      height: 15px;
      margin-top: 30px;
      background: url('../images/ico_point.svg') no-repeat center center;

      @include mq(sp) {
        margin-top: 20px;
      }
    }

    &--secondary {
      margin: 30px 0;
      padding: 5px 0 5px 20px;
      font-family: $font-min;
      font-size: 24px;
      line-height: 1.5;
      border-left: 4px solid $green;

      @include mq(sp) {
        padding: 5px 0 5px 10px;
        font-size: 20px;
      }
    }

    &--tertiary {
      font-weight: bold;
      margin: 30px 0;
      padding-left: 24px;
      text-indent: -24px;
      line-height: 1.5;
     
      &::before {
        display: inline-block;
        content: "";
        width: 12px;
        height: 12px;
        margin-right: 10px;
        background: $gray-no2;
      }

      @include mq(sp) {
        margin: 20px 0;
      }
    }
  }

  &__list {
    li:not(:first-child) {
      margin-top: 20px;

      @include mq(sp) {
        margin-top: 15px;
      }
    }
  }
}