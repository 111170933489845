/* --------------------------

  お知らせを投稿する際のスタイル

-------------------------- */

.p-article-content {
  &::after {
    display: block;
    content: "";
    clear: both;
  }

  h1 {
    margin: 30px 0;
    padding: 5px 0 5px 20px;
    font-family: $font-min;
    font-size: 24px;
    line-height: 1.5;
    border-left: 4px solid $green;

    @include mq(sp) {
      padding: 5px 0 5px 10px;
      font-size: 20px;
    }
  }

  h2 {
    font-weight: bold;
    margin: 20px 0;
    padding-left: 24px;
    text-indent: -24px;
    line-height: 1.5;
    
    &::before {
      display: inline-block;
      content: "";
      width: 12px;
      height: 12px;
      margin-right: 10px;
      background: $gray-no2;
    }

    @include mq(sp) {
      margin: 30px 0;
    }
  }

  h3 {
    margin: 20px 0;
    font-weight: bold;
  }

  p {
    margin: 40px 0;
    line-height: 2.25;

    @include mq(sp) {
      margin: 20px 0;
    }

    &::after {
      content: "";
      display: block;
      clear: both;
    }
  }

  strong,
  b {
    font-weight: bold;
  }

  em {
    font-style: oblique;
  }

  ul {
    margin: 30px 0;

    li {
      line-height: 1.5;

      &:not(:first-child) {
        margin-top: 10px;
      }
    }

    p {
      margin: 0;

      &::before {
        display: inline-block;
        content: "・";
        margin-right: 10px;
      }
    }
  }

  ol {
    margin: 30px 0;

    li {
      line-height: 1.5;
      list-style-position: inside; 
      list-style-type: decimal;

      &:not(:first-child) {
        margin-top: 10px;
      }
    }

    p {
      margin: 0;
    }
  }

  blockquote {
    margin: 30px 0;
    background: $gray-no3;
    border-radius: 8px;

    p {
      padding: 20px 30px;
    }
  }

  pre {
    margin-top: 20px;
    font-family: $font-min;
  }

  a {
    color: $green;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  hr {
    margin: 20px 0;
  }

  img {
    width: auto;
    height: auto;
  }

  img.size-large,
  img.size-full {
    width: 100%;
    max-width: 780px;
  }

  img.size-medium {
    width: calc(50% - 20px);

    @include mq(sp) {
      width: 100%;
    }

    &:not(:first-child) {
      margin: 0 0 0 20px;

      @include mq(sp) {
        margin: 0;
      }
    }

    + .wp-caption-text {
      width: calc(50% - 20px);

      @include mq(sp) {
        width: 100%;
      }
    }
  }

  img.size-thumbnail {
    @include mq(sp) {
      width: 50%;
      margin: 20px auto;
    }

    + .wp-caption-text {
      width: 150px;

      @include mq(sp) {
        width: 100%;
      }
    }
  }

  .wp-caption-text {
    margin: 10px 0;
    font-size: 12px;
    line-height: 2;
    text-align: center;
  }

  .alignleft {
    display: block;
    float: left;
    margin-right: 20px;

    @include mq(sp) {
      width: 100%;
      float: none;
      margin: 20px 0;
    }

    img {
      width: auto;

      @include mq(sp) {
        width: 100%;
      }

      + .wp-caption-text {
        width: auto;
      }
    }

    .size-medium {
      min-width: 370px;

      @include mq(sp) {
        min-width: auto;
        width: 100%;
      }

      &:not(:first-child) {
        margin: 0 0 0 20px;

        @include mq(sp) {
          margin: 0;
        }
      }

      + .wp-caption-text {
        min-width: 370px;

        @include mq(sp) {
          min-width: auto;
        }
      }
    }
  }

  .aligncenter {
    display: block;
    margin: auto;

    img {
      display: block;
      margin: auto;

      + .wp-caption-text {
        width: 100%;
      }
    }
  }

  .alignright {
    display: block;
    float: right;
    margin-left: 20px;

    @include mq(sp) {
      width: 100%;
      float: none;
      margin: 20px 0;
    }

    img {
      width: auto;

      @include mq(sp) {
        width: 100%;
      }

      + .wp-caption-text {
        width: auto;
      }
    }

    .size-medium {
      min-width: 370px;

      @include mq(sp) {
        min-width: auto;
        width: 100%;
      }

      &:not(:first-child) {
        margin: 0 20px 0 0;

        @include mq(sp) {
          margin: 0;
        }
      }

      + .wp-caption-text {
        min-width: 370px;

        @include mq(sp) {
          min-width: auto;
        }
      }
    }
  }
}