.p-summary {
  margin: 0 0 0 20px;
  padding: 15px 30px 20px;
  border-left: 2px solid $gold;

  @include mq(sp) {
    width: 100%;
    margin: 0;
    padding: 15px 20px;
  }

  &__ttl {
    font-family: $font-min;
    font-size: 24px;
    line-height: 1.4;

    @include mq(sp) {
      font-size: 20px;
    }

    span {
      display: block;
      margin-left: 0;
      margin-top: 10px;
      font-size: 14px;
      line-height: 1.5;

      @include mq(sp) {
        font-size: 12px;
      }
    }
  }

  &__list {
    font-size: 14px;
    line-height: 30px;
  }
}