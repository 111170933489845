.u-br {
  &--pc {
    display: block;

    @include mq(sp) {
      display: none;
    }
  }

  &--sp {
    display: inline;

    @include mq(sp) {
      display: block;
    }
  }
}