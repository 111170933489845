.l-inner {
  padding: 0 115px;
  
  @include mq(tb) {
    padding: 0 60px;
  }

  @include mq(sp) {
    padding: 0 20px;
  }

  &--min {
    max-width: 780px;
    padding: 0;
    margin: auto;

    @include mq(tb) {
      padding: 0 20px;
    }
  }
}