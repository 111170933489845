@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/NotoSerifCJKjp-Regular.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Noto Serif Japanese";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/NotoSerifCJKjp-SemiBold.otf") format("opentype");
  font-display: swap;
}

$font-size: 1rem;
$font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "Osaka", sans-serif;
$font-min: "Noto Serif Japanese", serif;
$line-height: 1.5;