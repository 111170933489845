.c-block {
  display: flex;

  @include mq(sp) {
    flex-direction: column;
  }

  &--reverse {
    &:nth-child(odd) {
      flex-direction: row-reverse;
  
      @include mq(sp) {
        flex-direction: column;
      }
    }
  }

  &--center {
    align-items: center;
  }
}