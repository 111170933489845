.p-mv {
  width: 100%;
  height: calc(100vh - 92px);
  margin-bottom: 94px;
  position: relative;
  z-index: 0;
  
  @include mq(tb) {
    margin: 0;
  }

  &::after {
    display: block;
    content: "";
    width: calc(100% - 2px);
    height: 200px;
    background: $white;
    border: 1px solid $white;
    position: absolute;
    left: 0;
    bottom: -200px;
    z-index: 1;
  }

  &::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: (5 / 9) * 100%;
  }

  &__ttl {
    font-family: $font-min;
    font-size: 28px;
    font-weight: bold;
    line-height: 60px;
    color: $white;
    text-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    @include v-Centering;
    left: 7%;
    z-index: 1000;

    @include mq(sp) {
      left: 10%;
      font-size: 24px;
      line-height: 46px;
    }
  }

  &__image {
    width: 100%;
    height: calc(100vh - 92px);
    position: absolute;
    top: 0;
  }

  &__slider {
    width: 100%;
    height: 100%;
    overflow: hidden;

    li {
      width: 100%;
      height: 100%;
      background-size: cover;
    }

    .moving {
      animation: scale 20s linear forwards;
    }

    @keyframes scale {
      0% {
        transform: scale(1);
      }
      100% {
        transform: scale(1.2);
      }
    }
  }

  &__slider-mv01 {
    background: url('../images/img_mv.jpg') no-repeat 50%;
  }

  &__slider-mv02 {
    background: url('../images/img_mv02.jpg') no-repeat 50%;
  }

  &__slider-mv03 {
    background: url('../images/img_mv03.jpg') no-repeat 50%;
  }

  &__slider-mv04 {
    background: url('../images/img_mv04.jpg') no-repeat 50%;
  }

  .slick-list {
    width: 100%;
    height: 100%;
  }

  .slick-track {
    height: 100%;
  }

}