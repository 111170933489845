.p-guidance-member {
  &__group {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  &__job {
    min-width: 120px;
    margin-right: 10px;
  }
}