.p-subnav-arrow {
  display: none;
  width: 30px;
  height: 100%;
  background: #fcfcfc;
  position: absolute;
  top: 0;
  z-index: 10;

  &::before {
    display: block;
    content: "";
    width: calc(100% - 2px);
    height: calc(100% - 20px);
    margin: 10px 0;
  }

  img {
    display: block;
    width: 10px;
    @include centering;
  }

  &--prev {
    left: 0;

    &::before {
      border-right: 1px solid $gray-no4;
    }
  }

  &--next {
    right: 0;

    &::before {
      border-left: 1px solid $gray-no4;
    }
  }
  
  &:disabled {
    visibility: hidden;
    opacity: 0;
  }

  &.show {
    display: block;
  }
}