.c-btn {
  max-width: 370px;
  width: 100%;
  padding: 20px;
  text-align: center;
  font-family: $font-min;
  background: $white;
  border: 1px solid $green;
  border-radius: 8px;
  color: $green;
  position: relative;
  @include transition(all, 0.3s);

  @include mq(sp) {
    width: calc(100% - 40px);
    margin: auto;
  }

  &::after {
    display: block;
    content: "";
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    @include centering;
  }

  &:hover {
    opacity: 0.7;
    transform: translateY(-3px);
    box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
  }

  &--small {
    max-width: 146px;
    padding: 15px;

    @include mq(sp) {
      max-width: 100%;
    }
  }

  &--small-secondary {
    max-width: 300px;
    padding: 15px;

    @include mq(sp) {
      width: calc(100% - 40px);
    }
  }
}