.c-sec-ttl {
  text-align: center;
  font-family: $font-min;

  &__ja {
    display: block;
    font-size: 30px;
    color: $green;
    padding-bottom: 15px;
    position: relative;

    @include mq(sp) {
      font-size: 20px;
    }

    &::after {
      display: block;
      content: "";
      width: 370px;
      height: 1px;
      background: linear-gradient(to right, #ffffff 0%,#be9f33 15%,#be9f33 85%,#ffffff 100%);
      @include h-Centering;
      bottom: 0;

      @include mq(sp) {
        width: calc(100% - 40px);
      }
    }
  }

  &__en {
    display: block;
    margin-top: 10px;
    font-size: 10px;
    color: $gray;
  }
}