.c-list {
  &__item {
    padding: 30px 20px;
    border-bottom: 1px solid $gray-no2;

    @include mq(sp) {
      padding: 20px 10px;
    }

    &:first-child {
      border-top: 1px solid $gray-no2;
    }
  }

  &__ttl {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 14px;
  }

  &__body {
    font-size: 14px;

    li:nth-child(n+5) {
      margin-top: 20px;
    }

    li:not(:first-child) {
      @include mq(sp) {
        margin-top: 20px;
      }
    }
  }
}