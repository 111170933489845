.l-footer-nav {
  width: 100%;
  max-width: 1170px;
  margin: auto;
  padding: 40px 0;
  border-bottom: 1px solid $gray-no2;
  font-family: $font-min;
  font-size: 12px;

  @include mq(sp) {
    display: none;
  }

  &__list {
      display: flex;
      justify-content: space-between;

      &:not(:first-child) {
        margin-right: 20px;
      }

      li {
        p {
          font-weight: bold;
        }
      }

      a {
        border-bottom: 1px solid $white;
        @include transition(all, 0.3s);

        &:hover {
          border-bottom: 1px solid $green;
          color: $green;
        }
      }
  }

  &__sub-list {
    li {
      margin-top: 20px;
    }
  }
}