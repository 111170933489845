#wpmem_login,
#wpmem_reg {
  form {
    width: 100%;
    max-width: 400px;
    margin: auto !important;
  }

  .form-login {
    max-width: 780px;
  }

  fieldset {
    margin: 0 !important;

    @include mq(sp) {
      width: calc(100% - 40px) !important;
      margin: auto !important;
    }
  }

  legend {
    display: block;
    font-family: $font-min;
    text-align: center;
    margin: 0 0 40px 0 !important;
  }

  .label-wrap {
    max-width: 400px;
    margin: auto;
  }

  .div_text,
  .button_div,
  .link-text {
    width: 100% !important;
    text-align: center !important;
  }

  input[type=checkbox] {
    width: 20px;
    height: 20px;
    margin: 0 5px 0 0 !important;
    border: 1px solid $gray-no2;
    transform: translateY(2px);
    position: relative;

    &::after {
      display: block;
      content: "";
      width: 10px;
      height: 10px;
      background: url(../images/ico_check.svg) no-repeat;
      @include centering;
    }

    &:checked {
      background: $gold;
    }
  }

  .buttons,
  .link-text a {
    display: block;
    max-width: 370px;
    width: 100%;
    margin: 20px auto;
    padding: 15px;
    text-align: center;
    font-family: $font-min;
    background: $green;
    border-radius: 8px;
    color: $white;
    position: relative;
    @include transition(all, 0.3s);

    @include mq(sp) {
      width: calc(100% - 40px);
      margin: 20px auto 0;
    }

    &:hover {
      opacity: 0.7;
      transform: translateY(-3px);
      box-shadow: 0 2px 7px rgba(0, 0, 0, 0.2);
    }
  }

  .link-text {
    margin-top: 20px;
  }

  .link-text-register {
    font-family: $font-min;
  }

  .lead {
    margin: 0 0 30px;
  }

  .caption {
    max-width: 400px;
    margin: 20px auto;
    font-size: 14px;
  }
}

#wpmem_msg,
.wpmem_msg {
  width: 74% !important;
  margin: 0 auto 40px;
  padding: 20px !important;

  @include mq(sp) {
    width: calc(100% - 40px) !important;
  }

  p {
    line-height: 1.5;
  }
}